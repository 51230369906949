<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";

    export default {
        name: 'App',
        
        
        methods:
            {
                async check_version()
                {
                    var local_version  = settings.version;
                    var server_version = await this.post("version");
                    if( local_version != server_version )
                    {
                        this.$notify({
                            title: 'Atentie',
                            message: 'A aparut o noua versiune - <button onclick="window.location.reload()">Upgrade</button> ',
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            duration: 0
                        });
                    }
                },
                post: async function(url, args={}){
                    var response = await this.$http.post(url, args );
                    return response.bodyText;
                },
            },
        mounted()
        {
            //verificam daca exista o versiune mai noua
            this.check_version();
        }
    }
</script>

<style lang='less'>

    @albastru-deschis: #44b0cc;

    .text-albastru{
        color: @albastru-deschis;
    }

    .bg-albastru{
        background-color: @albastru-deschis;
    }

    div.titlu{
        background-color: #0d7aa3;
        h1{
            color: white;
            padding: 8px 15px;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
        }
        .btn-adauga{
            float: right;
            height: 72px;
            padding-left: 50px;
            padding-right: 50px;
            background: #34c2e4; /* Old browsers */
            background: -moz-linear-gradient(top, #34c2e4 0%, #42b6df 50%, #4fabda 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #34c2e4 0%,#42b6df 50%,#4fabda 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #34c2e4 0%,#42b6df 50%,#4fabda 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c2e4', endColorstr='#4fabda',GradientType=0 ); /* IE6-9 */
            border: none;
            border-radius: 0%;
            margin: 2px;
        }
    }

    .el-header{
        padding: 0 5px !important;
    }

    html {
        height: 100%;
        background-position: center;
        background-size: cover;
        background-position: fixed;
        background-image: linear-gradient(to bottom, #30c6e6 0%, rgba(84, 167, 216, 0.6) 100%), url(./assets/login-bg.jpg);
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    body{
        background-color: transparent !important;
    }
    .my-dialog-class .el-dialog{
        width: 90%;
        margin-top: 5vh !important;
        @media(min-width: 768px){
            margin-top: 15vh !important;
            width: 70%;
        }
    }

    .el-message-box{
        width: 50% !important;
        @media(min-width: 768px){
            width: 420px;
        }
    }

    .btn-aplica{
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        @media(min-width: 1024px){
            margin-top: 40px !important;
            margin-bottom: 40px !important;
        }
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
        label{
            width: 100% !important;
        }
        .el-form-item__label{
            width: 100% !important;
            text-align: left;
        }
    }
    .el-form-item{
        margin-bottom: 0px !important;
    }
    
    .el-form-item__label{
        padding: 0 !important;
        margin: 0 !important;
    }

    .full-width{
        width: 100% !important;
    }

     .filter-buttons {
        margin-top: 0px;
        @media(min-width: 768px){
            margin-top: 0px
        }
        
    }
    .filter-buttons-right {
        margin-top: 0px;
        @media(min-width: 768px){
            margin-top: 0px
        }
        text-align: right;
    }
    .el-card__body{
        padding-bottom: 20px;
         @media(min-width: 768px){
            padding-bottom: 0px !important;
        }
    }


    .my-popper{
        max-width: 320px !important;
    }



    .statii{
        .el-select-dropdown__item{
            height: 60px !important;
            line-height: 25px !important;
        }
    }
    a.router-link-active{
        font-weight: bold;
        color: #007aff !important;
    }
    .el-select-dropdown{
        max-width: 100px !important;
    }
    .el-select-dropdown__item{
        white-space: wrap;    
    }

    .hidden-mobile {
        display: none;
        @media(min-width:1024px) {
            display: block;
        }
    }

    .hidden-desktop {
        display: block;
        @media(min-width:1024px) {
            display: none;
        }
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
        border-bottom: 2px solid #409EFF !important;
    }
    .no-padding-card{
        .el-card__body{
            padding:0px 0px 0px 5px !important;
        }
    }

    @keyframes blink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: .5;
        }
        100% {
            opacity: 1;
        }
    }

    .filtre-albastre{
        margin: 5px 0px 5px 0px; 
        padding: 0px !important; 

        .el-card__body{
            background: rgb(179, 216, 255) !important;
        }
        .el-collapse-item__header{
            font-weight:bold;
            font-style: italic;
            font-size: 16px;
            background: rgb(179, 216, 255) !important;
        }
        .el-collapse-item__content{
            background-color: rgb(179, 216, 255) !important;
        }
        .el-collapse-item__arrow{
            font-weight: 800;
            font-size: 20px;
        }
    }

    .rand-depasit{
        background-color: pink !important;
    }
    .rand-atentionare{
        background-color: oldlace !important;
    }
    .el-tag{
        font-weight: bold;
        color: black !important;
    }
    .el-table .cell{
        word-break: break-word !important;
    }
    .el-dialog__body {
        padding: 0px 30px !important;
        @media(min-width: 768px) {
            padding: 30px 30px !important;
        }
    }
</style>
