import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/'                     , name: 'home'                  , component: () => import ( './pages/Login.vue'                      ) },
        { path: '/categorii-drepturi'   , name: 'categorii-drepturi'    , component: () => import ('./pages/categorii-drepturi/Categorii-drepturi_list.vue'     ) },
        { path: '/drepturi'             , name: 'drepturi'              , component: () => import ('./pages/drepturi/Drepturi_list.vue'                         ) },
        { path: '/grupuri-utilizatori'  , name: 'grupuri-utilizatori'   , component: () => import ('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue'   ) },
        { path: '/utilizatori'          , name: 'utilizatori'           , component: () => import ('./pages/utilizatori/Utilizatori_list.vue'                   ) },
        { path: '/vanzari'              , name: 'vanzari'               , component: () => import ( './pages/vanzari/Vanzari_list.vue'                          ) },
        { path: '/clienti'              , name: 'clienti'               , component: () => import ( './pages/clienti/Clienti_list.vue'                          ) },
        { path: '/locatii'              , name: 'locatii'               , component: () => import( './pages/locatii/Locatii_list.vue'                           ) },
        { path: '/firme'                , name: 'firme'                 , component: () => import( './pages/firme/Firme_list.vue'                               ) },
        { path: '/statii'               , name: 'statii'                , component: () => import( './pages/statii/Statii_list.vue'                             ) },
        { path: '/licente-list'         , name: 'licente-list'          , component: () => import( './pages/licente/Licente_list.vue'                           ) },
        { path: '/licente'              , name: 'licente'               , component: () => import( './pages/licente/Licente.vue'                                ) },
        { path: '/grid-incasari'        , name: 'grid-incasari'         , component: () => import( './pages/grid-incasari/GridIncasari.vue'                     ) },
        { path: '/incasari'             , name: 'incasari'              , component: () => import( './pages/incasari/Incasari.vue'                              ) },
        { path: '/taskuri'              , name: 'taskuri'               , component: () => import( './pages/taskuri/Taskuri.vue'                                ) },
        { path: '/facturi'              , name: 'facturi'               , component: () => import( './pages/facturi/Facturi_List.vue'                           ) },
        { path: '/no-rights'            , name: 'no-rights'             , component: () => import( './pages/NoRightsPage.vue'                                   ) },
    ]
})
